
import Vue from 'vue';
import config from "@/config";

export default Vue.extend({
    name: 'care',
    components: {},
    data() {
        return {
            loading:true, 
            isPrimary:true,
            title:'领导关怀',
            isZoomed:false,
            params:{ server:'1',category_id:'',event_id:'',page:1,limit:100000},
            total:0,
            events: [],
            // items:[],
            details:{
                content:'',
             },
            timeoutId: null,
          }
    },
    created() {
        this.postList(this.$route.query.classify as string);
        if(this.$route.query.classify  == '10') this.title = '领导关怀'
        else if(this.$route.query.classify  == '11') this.title = '建设成就'
        else if(this.$route.query.classify  == '12') this.title = '驻地文化'
        else this.title = '没有了'
    },
    computed: {
        items() {
            return this.events
        },
    },
    mounted() {
        this.resetTimeout();
        // 添加事件监听器，当用户有鼠标移动或键盘输入时重置超时计时器
        document.addEventListener('mousemove', this.resetTimeout);
        document.addEventListener('keydown', this.resetTimeout);
    },
    beforeDestroy() {
        // 在组件销毁前，清理事件监听器和超时计时器
        document.removeEventListener('mousemove', this.resetTimeout);
        document.removeEventListener('keydown', this.resetTimeout);
        clearTimeout(this.timeoutId);
    },
    methods: {
        resetTimeout() {
            // 重置超时计时器
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.$router.push('/'); // Replace '/' with the path to your home page
            }, 60000);
        },
        getFullImagePath(relativePath: string) {
            return `${config.domain}${relativePath}`;
        },
        openimg(item) {
            if (item.image && Array.isArray(item.image) && item.image.length > 0) {
                // 如果满足条件，将第一个元素赋给 this.details.picImg
                this.details.picImg = item.image[0];
                this.details = item
            }
            this.isZoomed = !this.isZoomed;
        },
        goBack(){
            this.$router.replace({path:'/home'});
        },
        async postList(classify) {
            try {
                this.params.category_id = classify
                const result = await this.$api.postList(this.params);
                this.events = [...this.events, ...result.data.list];
                this.events.push(...this.events)
                this.total = result.data.total
                this.loading = false
                this.isPrimary = false
            } catch (error) {
                console.log('error', error)
            }
        },
        handleCarouselChange() {
            const limitlen = this.total / this.params.limit
            if (this.params.page >= limitlen) {
                return;
            } else {
                console.log("还有数据")
            }
            this.params.page++;
            this.postList(this.$route.query.classify as string);
        },
    }
});
